import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { ProductService } from 'src/app/modules/product/services/product.service';
import { PrintfulService } from '../../services/printful.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.scss']
})
export class ProductAddComponent {

  printfulVariants;

  product = new FormGroup({
    type: new FormControl('GENERIC', Validators.required),
    name: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    variants: new FormArray([], Validators.required)
  });

  addvariant = new FormGroup({
    images: new FormArray([], Validators.required),
    color: new FormControl('#000000'),
    size: new FormControl(),
    price: new FormControl(null, [Validators.required, Validators.min(0)]),
    stock: new FormControl(-1, Validators.required),
    ean: new FormControl(),
    sku: new FormControl(),
    printfulVariant: new FormControl(null)
  });

  listenTypeChangesSubscription: Subscription;

  constructor(private router: Router,
              private printfulService: PrintfulService,
              private productService: ProductService) { 
                this.listenTypeChanges();
              }

  public addVariant() {
    const formArray = this.product.get('variants') as FormArray;
    const images = new FormArray(this.addvariant.get('images').value?.map(v => new FormControl(v)));
    const price = new FormControl((this.addvariant.get('price').value * 100).toFixed(0), Validators.required);
    const stock = new FormControl(this.addvariant.get('stock').value, Validators.required);
    let size = null;
    let color = null;
    let ean = null;
    let sku = null;
    
    if (this.addvariant.get('printfulVariant').value) {
      size = new FormControl(this.addvariant.get('printfulVariant').value?.size);
      color = new FormControl(this.addvariant.get('printfulVariant').value?.color);
      ean = new FormControl(this.addvariant.get('printfulVariant').value?.id?.toString());
      sku = new FormControl(this.addvariant.get('printfulVariant').value?.id?.toString());
    } else {
      size = new FormControl(this.addvariant.get('size').value);
      color = new FormControl(this.addvariant.get('color').value);
      ean = new FormControl(this.addvariant.get('ean').value);
      sku = new FormControl(this.addvariant.get('sku').value);
    }
    formArray.push(new FormGroup({ images, price, stock, size, color, ean, sku }));
  }

  public images(images) {
    images?.forEach(image => (this.addvariant.get('images') as FormArray).push(new FormControl(image)));
  }

  public saveProduct() {
    this.productService.addProduct(this.product).pipe(
      take(1),
      tap(o => this.router.navigate(['/products']))
    ).subscribe();
  }

  public deleteVariant(i) {
    const formArray = this.product.get('variants') as FormArray;
    formArray.removeAt(i);
  }

  private listenTypeChanges() {
    this.listenTypeChangesSubscription = this.product.get('type').valueChanges.pipe(
      tap(type => this.addvariant.get('ean').reset()),
      tap(type => this.addvariant.get('sku').reset()),
      tap(type => this.getPrintfulVariants())
    ).subscribe();
  }

  private getPrintfulVariants() {
    return this.printfulService.getVariants().pipe(
      tap(variants => this.printfulVariants = variants)
    ).subscribe();
  }

}
