<table *ngIf="products?.length">
    <thead>
        <tr>
            <th></th>
            <th>Name</th>
            <th class="center">Price</th>
            <th class="center">Qty</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let p of products">
            <td></td>
            <td [title]="p?.product_name">{{ p?.product_name }}</td>
            <td class="center" [title]="p?.product_price | currency">{{ p?.product_price | currency }}</td>
            <td class="center" [title]="p?.product_qty">{{ p?.product_qty }}</td>
            <td class="right"></td>
        </tr>
    </tbody>
</table>
<figure *ngIf="!products?.length">
    <ng-lottie [options]="animationOptions"></ng-lottie>
    <p>Add a new product clicking "ADD PRODUCT" button</p>
</figure>
