import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-product-variants',
  templateUrl: './product-variants.component.html',
  styleUrls: ['./product-variants.component.scss']
})
export class ProductVariantsComponent {

  @Input() variants;
  @Output() delete = new EventEmitter();
  animationOptions = { path: 'assets/animations/empty.animation.json' };

  public deleteItem(e) {
    this.delete.emit(e)
  }

}
