import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class PrintfulDaoService {

    constructor(private http: HttpClient) { }

    public getProducts() {
        return this.http.get(`${environment.restURL}/printful/products`);
    }
    
}
