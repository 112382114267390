import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rrss',
  templateUrl: './rrss.component.html',
  styleUrls: ['./rrss.component.scss']
})
export class RrssComponent implements OnInit {

  @Input() rrss;

  constructor() { }

  ngOnInit(): void { }

}
