import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';

@Injectable()
export class OrderDaoService {

    constructor(private apollo: Apollo) { }

    public getSellerOrders() {
        const getSellerOrders = gql`
            query getSellerOrders {
                getSellerOrders {
                    createdAt
                    id
                    identifier
                    status
                    customer {
                        name
                        lastname
                    }
                    products {
                        product_id
                        product_name
                        product_price
                        product_qty
                    }
                }
            }
        `;
        return this.apollo.watchQuery<any>({
            query: getSellerOrders
        }).valueChanges.pipe( map(result => result.data.getSellerOrders));
    }
    
    public getSellerOrder(identifier) {
        const getSellerOrder = gql`
            query getSellerOrder($identifier: String!) {
                getSellerOrder(identifier: $identifier) {
                    createdAt
                    id
                    identifier
                    status
                    customer {
                        name
                        lastname
                        email
                        phone1
                        phone2
                        address {
                            alias
                            line1
                            line2
                            city
                            state
                            country
                            zip
                        }
                    }
                    products {
                        product_id
                        product_name
                        product_price
                        product_qty
                    }
                }
            }
        `;
        return this.apollo.watchQuery<any>({
            query: getSellerOrder,
            variables: { identifier }
        }).valueChanges.pipe( map(result => result.data.getSellerOrder));
    }

}
