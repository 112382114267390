import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ProductService } from 'src/app/modules/product/services/product.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {

  products;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private productService: ProductService) {}

  ngOnInit() {
    this.getProducts();
  }

  public gotoAddProduct() {
    this.router.navigate(['add'], { relativeTo: this.activatedRoute});
  }

  public deletedItem() {
    this.getProducts();
  }

  private getProducts() {
    this.productService.getProducts().pipe(
      take(1),
      map(res => this.products = res),
      catchError(err => {console.dir(err); return EMPTY })
    ).subscribe();
  }

}
