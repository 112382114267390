import { Injectable, isDevMode } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { tap } from 'rxjs/operators';
import { MultimediaDaoService } from './multimedia.dao.service';

@Injectable({
  providedIn: 'root'
})
export class MultimediaService {

  private $openUploader = new Subject();
  private $closeUploader = new Subject();

  constructor(private multimediaDaoService: MultimediaDaoService) { }

  public getMultimediaBySellerId() {
    return this.multimediaDaoService.getMultimediaBySellerId().pipe(
      tap(res => isDevMode() ? console.dir(res) : null)
    );
  }

  public uploadFile(file) {
    return this.multimediaDaoService.uploadFile(file).pipe(
      tap(res => isDevMode() ? console.dir(res) : null)
    )
  }

  public listenOpenUploader() {
    return this.$openUploader.asObservable();
  }

  public openUploader() {
    this.$openUploader.next();
    return this.$closeUploader;
  }
  
  public listenCloseUploader() {
    return this.$closeUploader.asObservable();
  }

  public closeUploader(data?) {
    this.$closeUploader.next(data);
  }

}
