import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MultimediaDaoService {

  constructor(private apollo: Apollo,
              private http: HttpClient
  ) { }

  public getMultimediaBySellerId() {
    const getMultimediaBySellerId = gql`
      query getMultimediaBySellerId {
        getMultimediaBySellerId {
          id
          createdAt
          type
          original_url
          thumbnail_url
          small_url
          medium_url
          large_url
          alt_text
        }
      }
    `;
    return this.apollo.watchQuery<any>({
        query: getMultimediaBySellerId
    }).valueChanges.pipe(map(result => result.data.getMultimediaBySellerId));
  }
  
  public uploadFile(file) {
    const headers = new HttpHeaders();
    headers.append('UploadFilename', file.name);
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${environment.restURL}/multimedia/upload`, formData, { headers });
  }

}
