import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserService } from 'src/app/modules/user/services/user.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent {

  @Output() data = new EventEmitter();

  roles = ['SELLER', 'BLOGGER'];
  signinForm = new FormGroup({
    role: new FormControl(this.roles[0]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, Validators.required)
  });
  error;

  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  public signIn() {
    this.userService.signIn(this.signinForm.get('role').value, this.signinForm.get('email').value, this.signinForm.get('password').value).pipe(
      tap(res => this.router.navigate(['/home'])),
      catchError(err => {
        this.error = err?.message;
        return EMPTY;
      })
    ).subscribe();
  }

}
