<table>
    <thead>
        <tr>
            <th></th>
            <th>Date</th>
            <th>ID Order</th>
            <th>Customer</th>
            <th class="center">Products</th>
            <th class="center">Price</th>
            <th>Status</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let o of orders" (click)="viewOrder(o?.identifier)">
            <td></td>
            <td>{{ o?.createdAt | date: ' hh:mm mm/dd/yy' }}</td>
            <td [title]="o?.identifier">{{ o?.identifier }}</td>
            <td [title]="o?.customer?.name + ' ' + o?.customer?.lastname">{{ o?.customer?.name + ' ' + o?.customer?.lastname }}</td>
            <td class="center" [title]="o?.total_qty">{{ o?.total_qty }}</td>
            <td class="center" [title]="o?.total_price | currency">{{ o?.total_price | currency }}</td>
            <td [title]="o?.status"><div class="status" [ngClass]="o?.status">{{ o?.status }}</div></td>
            <td>
                <button (click)="viewOrder(o?.identifier)" title="View"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg></button>
            </td>
        </tr>
    </tbody>
</table>
