import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { RRSSRoutingModule } from './rrss-routing.module';
import { RrsssComponent } from './components/rrsss/rrsss.component';
import { RrssAddComponent } from './components/rrss-add/rrss-add.component';
import { RrssEditComponent } from './components/rrss-edit/rrss-edit.component';
import { RrssDiffusionAddComponent } from './components/rrss-diffusion-add/rrss-diffusion-add.component';
import { RrssComponent } from './components/rrss/rrss.component';
import { RrssService } from './services/rrss-service.service';
import { RrssDaoService } from './services/rrss-dao.service';

@NgModule({
  declarations: [
    RrsssComponent,
    RrssComponent,
    RrssAddComponent,
    RrssEditComponent,
    RrssDiffusionAddComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RRSSRoutingModule, 
  ],
  providers: [
    RrssService,
    RrssDaoService
  ]
})
export class RRSSModule { }
