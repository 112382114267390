import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { ProductService } from 'src/app/modules/product/services/product.service';
import { PrintfulService } from '../../services/printful.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent {

  printfulVariants;

  product = new FormGroup({
    id: new FormControl(null, Validators.required),
    type: new FormControl(null, Validators.required),
    name: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    variants: new FormArray([], Validators.required)
  });

  addvariant = new FormGroup({
    images: new FormControl(null, Validators.required),
    color: new FormControl('#000000'),
    size: new FormControl(),
    price: new FormControl(null, [Validators.required, Validators.min(0)]),
    stock: new FormControl(-1, Validators.required),
    ean: new FormControl(),
    sku: new FormControl(),
    printfulVariant: new FormControl(null)
  });

  listenTypeChangesSubscription: Subscription;

  constructor(private productService: ProductService,
              private router: Router,
              private printfulService: PrintfulService,
              private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getProduct();
    this.listenTypeChanges();
  }

  public addVariant() {
    const formArray = this.product.get('variants') as FormArray;
    const images = new FormArray(this.addvariant.get('images').value?.map(v => new FormControl(v)));
    const price = new FormControl((this.addvariant.get('price').value * 100).toFixed(0), Validators.required);
    const stock = new FormControl(this.addvariant.get('stock').value, Validators.required);
    let size = null;
    let color = null;
    let ean = null;
    let sku = null;

    if (this.addvariant.get('printfulVariant').value) {
      size = new FormControl(this.addvariant.get('printfulVariant').value?.size);
      color = new FormControl(this.addvariant.get('printfulVariant').value?.color);
      ean = new FormControl(this.addvariant.get('printfulVariant').value?.id?.toString());
      sku = new FormControl(this.addvariant.get('printfulVariant').value?.id?.toString());
    } else {
      size = new FormControl(this.addvariant.get('size').value);
      color = new FormControl(this.addvariant.get('color').value);
      ean = new FormControl(this.addvariant.get('ean').value);
      sku = new FormControl(this.addvariant.get('sku').value);
    }
    formArray.push(new FormGroup({ images, price, stock, size, color, ean, sku }));
  }

  public deleteVariant(i) {
    const variant = (this.product.get('variants') as FormArray).at(i) as FormGroup;
    if (variant.contains('id')) {
      this.productService.deleteProductVariant(variant).pipe(
        take(1),
        tap(o => this.getProduct())
      ).subscribe();
    } else {
      (this.product.get('variants') as FormArray).removeAt(i);
    }
  }

  public getProduct() {
    const slug = this.activatedRoute.snapshot.paramMap.get('slug');
    this.productService.getProduct(slug).pipe(
      take(1),
      map(({ product, variants}) => this.resetForm(product, variants)),
    ).subscribe();
  }

  public updateProduct() {
    this.productService.updateProduct(this.product).pipe(
      take(1),
      tap(o => this.router.navigate(['/products']))
    ).subscribe();
  }

  public image(images) {
    this.addvariant.get('images').patchValue(images);
  }

  private resetForm(product, variants) {
    this.product.patchValue({
      id: product.id,
      type: product.type,
      name: product.name,
      description: product.description
    });
    (this.product.get('variants') as FormArray).clear();
    variants?.forEach(v => (this.product.get('variants') as FormArray).push(new FormGroup({
      id: new FormControl(v.id, Validators.required),
      images: new FormControl(v.images, Validators.required),
      color: new FormControl(v.color),
      size: new FormControl(v.size),
      price: new FormControl(v.price, [Validators.required, Validators.min(0)]),
      stock: new FormControl(v.stock, Validators.required),
      ean: new FormControl(v.ean),
      sku: new FormControl(v.sku),
    })));
  }

  private listenTypeChanges() {
    this.listenTypeChangesSubscription = this.product.get('type').valueChanges.pipe(
      tap(type => this.addvariant.get('ean').reset()),
      tap(type => this.addvariant.get('sku').reset()),
      tap(type => this.getPrintfulVariants())
    ).subscribe();
  }

  private getPrintfulVariants() {
    return this.printfulService.getVariants().pipe(
      tap(variants => this.printfulVariants = variants)
    ).subscribe();
  }

}
