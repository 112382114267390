import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency'
})
export class CustomCurrencyPipe implements PipeTransform {

  transform(value: any, currency?: any): unknown {

    if (value) {
      switch (currency?.toUpperCase()) {
        case 'EUR':
          currency = '€';
          break;
        default:
          currency = '€';
          break;
      }
      value = (value / 100).toFixed(2);
      return `${ value } ${ currency }`;
    }

    return value;
  }

}
