import { Component, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent { 

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.el.nativeElement.classList.contains('fullwidth')) {
      this.renderer.setStyle(this.el.nativeElement, 'flexBasis', '100%');
    }
  }

}
