import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductDaoService {

    constructor(private apollo: Apollo) { }

    public getProducts() {
        const getProducts = gql`
            query getProducts {
                getProducts {
                    product {
                        id
                        slug
                        name
                        min_price
                        colors {
                            raw
                            normalized {
                                name
                                hex
                            }
                        }
                    }
                    variants {
                        id
                        price
                        size
                        stock
                        images {
                            alt_text
                            thumbnail_url
                            medium_url
                        }
                        color {
                            raw
                            normalized {
                                name
                                hex
                            }
                        }
                    }
                }
            }
        `;
        return this.apollo.watchQuery<any>({
            query: getProducts
        }).valueChanges.pipe( map(result => result.data.getProducts));
    }

    public getProduct(slug) {
        const getProduct = gql`
            query getProduct($slug: String!) {
                getProduct(slug: $slug) {
                    product {
                        id
                        type
                        name
                        slug
                        description
                        min_price
                    }
                    variants {
                        id
                        price
                        size
                        stock
                        images {
                            alt_text
                            thumbnail_url
                            medium_url
                        }
                        ean
                        sku
                    }
                }
            }
        `;
        return this.apollo.watchQuery<any>({
            query: getProduct,
            variables: { slug }
        }).valueChanges.pipe( map(result => result.data.getProduct));
    }

    public addProduct(type, name, description, variants) {
        const addProductInput = { type, name, description, variants };
        const addProduct = gql`
            mutation addProduct($addProductInput: AddProductInput!) {
                addProduct(addProductInput: $addProductInput) {
                    product {
                        id
                        name
                        slug
                        description
                    }
                    variants {
                        id
                        properties
                        price
                        stock
                        images {
                            alt_text
                            thumbnail_url
                            medium_url
                        }
                        ean
                        sku
                    }
                }
            }
        `;
        return this.apollo.mutate<any>({
          mutation: addProduct,
          variables: { addProductInput }
        }).pipe( map(result => result.data.addProduct));
    }

    public updateProduct(id, type, name, description, variants) {
        const updateProductInput = { id, type, name, description, variants };
        const updateProduct = gql`
            mutation updateProduct($updateProductInput: UpdateProductInput!) {
                updateProduct(updateProductInput: $updateProductInput) {
                    product {
                        id
                        name
                        slug
                        description
                    }
                    variants {
                        id
                        properties
                        price
                        stock
                        images {
                            alt_text
                            thumbnail_url
                            medium_url
                        }
                        ean
                        sku
                    }
                }
            }
        `;
        return this.apollo.mutate<any>({
          mutation: updateProduct,
          variables: { updateProductInput }
        }).pipe( map(result => result.data.updateProduct));
    }
    
    public deleteProduct(product_id: number) {
        const deleteProduct = gql`
            mutation deleteProduct($product_id: Int!) {
                deleteProduct(product_id: $product_id) {
                    product
                    variants
                }
            }
        `;
        return this.apollo.mutate<any>({
          mutation: deleteProduct,
          variables: { product_id }
        }).pipe( map(result => result.data.deleteProduct));
    }
    
    public deleteProductVariant(variant_id: number) {
        const deleteProductVariant = gql`
            mutation deleteProductVariant($variant_id: Int!) {
                deleteProductVariant(variant_id: $variant_id)
            }
        `;
        return this.apollo.mutate<any>({
          mutation: deleteProductVariant,
          variables: { variant_id }
        }).pipe( map(result => result.data.deleteProductVariant));
    }
}
