<app-breadcrumb [breadcrumbs]="[{ name: 'Edit post' }]">
    <button (click)="back()">back</button>
    <button (click)="updatePost()" class="green" [disabled]="saving">SAVE</button>
</app-breadcrumb>
<app-card [formGroup]="postForm">
    <app-form-field>
        <input type="text" formControlName="slug" placeholder="Slug" label="Slug">
    </app-form-field>
    <div>
        <app-form-field>
            <select type="text" formControlName="type" placeholder="type" label="Type *" [compareWith]="compareFn">
                <option *ngFor="let type of types" [ngValue]="type">{{ type.content }}</option>
            </select>
        </app-form-field>
        <app-form-field>
            <select formControlName="parent" [compareWith]="compareFn" placeholder="Parent post" label="Parent post">
                <option *ngFor="let parent of parents" [ngValue]="parent">{{ parent.title }}</option>
            </select>
        </app-form-field>
    </div>
    <app-form-field>
        <input type="text" formControlName="title" placeholder="Title" label="Title">
    </app-form-field>
    <app-form-field>
        <input type="text" formControlName="image" placeholder="Image" label="Image">
    </app-form-field>
    <app-form-field>
        <input type="text" formControlName="metaTitle" placeholder="Meta title" label="Meta title" maxlength="55">
    </app-form-field>
    <app-form-field>
        <textarea type="text" formControlName="metaDescription" placeholder="Meta description" label="Meta description" rows="5" maxlength="160"></textarea>
    </app-form-field>
    <div>
        <app-form-field>
            <select formControlName="selectedTag" (change)="onAddTag()" [compareWith]="compareFn" placeholder="Tags" label="Tags">
                <option *ngFor="let tag of tags" [ngValue]="tag">{{ tag.content }}</option>
            </select>
        </app-form-field>
        <span *ngFor="let tag of postForm.get('tags').value" class="tag">{{ tag.content }}<i (click)="onDeleteTag(tag)" class="fas fa-times"></i></span>
    </div>
    <div>
        <app-form-field>
            <input type="number" formControlName="readtime" placeholder="Read time" label="Read time">
        </app-form-field>
        <ng-container [formGroup]="analysisForm">
            <app-form-field>
                <input type="text" formControlName="score" placeholder="Score rank" label="Score rank">
            </app-form-field>
            <app-form-field>
                <input type="text" formControlName="pros" placeholder="Score pro tips" label="Score pro tips">
            </app-form-field>
            <app-form-field>
                <input type="text" formControlName="cons" placeholder="Score const tips" label="Score const tips">
            </app-form-field>
        </ng-container>
    </div>
    <figure>
        <div *ngFor="let paragraph of paragraphsForm.controls; let i = index" [formGroup]="paragraph">
            <div>
                <app-form-field>
                    <input type="number" formControlName="position" placeholder="Position" label="Position">
                </app-form-field>
                <span>{{ paragraph.controls.htmlTag.value.content }} {{ paragraph.controls.classes.value }}</span>
            </div>
            <app-form-field>
                <textarea formControlName="content" placeholder="Content" label="Content" rows="5">{{ paragraph.controls.content.value }}</textarea>
            </app-form-field>
            <button (click)="deleteParagraph(i)" title="Delete"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M292.31-140q-29.92 0-51.12-21.19Q220-182.39 220-212.31V-720h-40v-60h180v-35.38h240V-780h180v60h-40v507.69Q740-182 719-161q-21 21-51.31 21H292.31ZM680-720H280v507.69q0 5.39 3.46 8.85t8.85 3.46h375.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-720ZM376.16-280h59.99v-360h-59.99v360Zm147.69 0h59.99v-360h-59.99v360ZM280-720v520-520Z"/></svg></button>
        </div>
        <p *ngIf="paragraphsForm.controls.length === 0">Todavia no has añadido ningún parrafo</p>
    </figure>
</app-card>
<app-card [formGroup]="addParagraphForm">
    <div>
        <app-form-field>
            <input type="number" formControlName="position" placeholder="Position" label="Position">
        </app-form-field>
        <app-form-field>
            <select formControlName="htmlTag" [compareWith]="compareFn" placeholder="Html tag" label="Html tag">
                <option *ngFor="let htmltag of htmltags" [ngValue]="htmltag">{{htmltag.content}}</option>
            </select>
        </app-form-field>
        <app-form-field>
            <input type="text" formControlName="classes" placeholder="Classes" label="Classes">
        </app-form-field>
    </div>
    <app-form-field>
        <textarea type="text" formControlName="content" placeholder="Paragraph" label="Paragraph" rows="5"></textarea>
    </app-form-field>
    <button (click)="addParagraph()" class="green">ADD PARAGRAPH</button>
</app-card>
<button (click)="updatePost()" class="green" [disabled]="saving">SAVE</button>
