<app-title content="Add product"></app-title>
<app-card header="Product">
    <ng-container [formGroup]="product">
        <app-form-field>
            <select formControlName="type" placeholder="type" label="Type *">
                <option value="GENERIC">GENERIC</option>
                <option value="PRINTFUL">PRINTFUL</option>
            </select>
        </app-form-field>
        <app-form-field>
            <input type="text" formControlName="name" placeholder="Name" label="Name *">
        </app-form-field>
        <app-form-field>
            <textarea formControlName="description" placeholder="Description" label="Description *" rows="10"></textarea>
        </app-form-field>
    </ng-container>
</app-card>
<app-card header="Variants">
    <app-product-variants [variants]="product?.get('variants').value" (delete)="deleteVariant($event)"></app-product-variants>
</app-card>
<app-card header="Add variant">
    <app-product-image [images]="addvariant.get('images')?.value" (changes)="images($event)"></app-product-image>
    <div [formGroup]="addvariant">
        <app-form-field>
            <input type="number" formControlName="price" placeholder="Price" label="Price *">
        </app-form-field>
        <app-form-field>
            <input type="number" formControlName="stock" placeholder="Stock" label="Stock *">
        </app-form-field>
        <ng-container *ngIf="product?.value?.type !== 'PRINTFUL'">
            <app-form-field>
                <input type="text" formControlName="size" placeholder="Size" label="Size">
            </app-form-field>
            <app-form-field>
                <input type="color" formControlName="color" placeholder="Color" label="Color">
            </app-form-field>
            <app-form-field>
                <input *ngIf="product?.value?.type !== 'PRINTFUL'" type="text" formControlName="ean" placeholder="EAN" label="EAN">
            </app-form-field>
            <app-form-field>
                <input  *ngIf="product?.value?.type !== 'PRINTFUL'" type="text" formControlName="sku" placeholder="SKU" label="SKU">
            </app-form-field>
        </ng-container>
        <ng-container *ngIf="product?.value?.type === 'PRINTFUL'">
            <app-form-field>
                <select *ngIf="product?.value?.type === 'PRINTFUL'" formControlName="printfulVariant" placeholder="Printful variant" label="Printful variant *">
                    <option *ngFor="let v of printfulVariants" [ngValue]="v">{{ v.name }} {{ v.price }} {{ v.size }} {{ v.color }}</option>
                </select>
            </app-form-field>
        </ng-container>
    </div>
    <button class="green" (click)="addVariant()" [disabled]="!addvariant.valid">ADD VARIANT</button>
</app-card>
<button class="green" (click)="saveProduct()" [disabled]="!product.valid">SAVE</button>