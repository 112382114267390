import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[label]'
})
export class FieldNameDirective implements OnInit {

  @Input('label') titleText: string = ''; // Recibe el texto como parámetro de la directiva

  constructor(
    private el: ElementRef, 
    private renderer: Renderer2) {}

  ngOnInit() {
    const existingLabels = this.el.nativeElement.parentNode.querySelectorAll('label');
    existingLabels.forEach((label: HTMLElement) => {
      this.renderer.removeChild(this.el.nativeElement.parentNode, label);
    });
    const titleElement = this.renderer.createElement('label');
    const text = this.renderer.createText(this.titleText);
    this.renderer.appendChild(titleElement, text);
    this.renderer.insertBefore(this.el.nativeElement.parentNode, titleElement, this.el.nativeElement);
  }
}