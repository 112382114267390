import { Injectable, isDevMode } from '@angular/core';
import { map, tap } from 'rxjs/internal/operators';
import { PrintfulDaoService } from './printful-dao.service';

@Injectable()
export class PrintfulService {

  constructor(private printfulDaoService: PrintfulDaoService) { }

  public getVariants() {
    return this.printfulDaoService.getProducts().pipe(
      tap(res => isDevMode() ? console.dir(res) : null),
      map((res: any[]) => {
        return res?.reduce(( acc, { product, variants } ) => {
          acc = [
            ...acc, 
            ...variants?.map(v => ({
              name: product.name,
              id: v.id, 
              price: v.price, 
              size: v.size,
              color: v.color
            })) 
          ];
          return acc;
        }, []);
      }
      )
    )
  }

}
