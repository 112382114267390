import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { map, take, tap } from 'rxjs/internal/operators';
import { PostService } from '../../services/post.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {

  posts;
  searchForm = new FormGroup({
    search: new FormControl()
  });

  constructor(private postService: PostService,
              private router: Router
  ) { }

  ngOnInit(): void {
    this.getPosts();
  }

  public addPost() {
    this.router.navigate(['./add']);
  }

  private getPosts() {
    this.postService.getPosts().pipe(
      take(1),
      map(res => this.posts = res)
    ).subscribe();
  }

  public deletePost(post, e) {
    e.stopPropagation();
    this.postService.deletePost(post.slug).pipe(
      take(1),
      tap(res => this.getPosts())
    ).subscribe();
  }

}
