import { Injectable } from '@angular/core';

@Injectable()
export class UserStorageService {

  public setAccessToken(access_token: string) {
    sessionStorage.setItem('dlvadmin_at', access_token);
  }
  
  public getAccessToken(): string {
    return sessionStorage.getItem('dlvadmin_at');
  }

  public deleteAccessToken() {
    return sessionStorage.deleteItem('dlvadmin_at');
  }

  public setRole(role: string) {
    sessionStorage.setItem('dlvadmin_r', role);
  }
  
  public getRole(): string {
    return sessionStorage.getItem('dlvadmin_r');
  }

  public deleteRole() {
    return sessionStorage.deleteItem('dlvadmin_r');
  }

}
