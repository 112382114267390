import { Component } from '@angular/core';
import { tap } from 'rxjs/internal/operators';
import { SitemapxmlService } from '../../services/sitemapxml.service';
import { PostService } from 'src/app/modules/post/services/post.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {

  constructor(private postService: PostService,
              private sitemapXMLService: SitemapxmlService) { }

  public generate() {
    this.postService.getPosts().pipe(
      tap(o => this.sitemapXMLService.generateSiteMapXML(o))
    ).subscribe();
  }
}
