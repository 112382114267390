import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-multimedia-upload-item',
  templateUrl: './multimedia-upload-item.component.html',
  styleUrls: ['./multimedia-upload-item.component.scss']
})
export class MultimediaUploadItemComponent {

  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  @Output() upload = new EventEmitter();

  public onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];
    if (file) {
      this.emit(file);
      this.fileInput.nativeElement.value = '';
    }
  }

  public onClick() { 
    this.fileInput.nativeElement.click();
  } 

  public onFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];
    if (file) {
      this.emit(file);
      this.fileInput.nativeElement.value = '';
    }
  }

  private emit(file) {
    this.upload.emit(file);
  }

}
