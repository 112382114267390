import { Injectable, isDevMode } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { map, tap } from 'rxjs/internal/operators';
import { ProductDaoService } from 'src/app/modules/product/services/product-dao.service';

@Injectable()
export class ProductService {

  constructor(private productDaoService: ProductDaoService) { }

  public getProducts() {
    return this.productDaoService.getProducts().pipe(
      tap(res => isDevMode() ? console.dir(res) : null)
    )
  }
  
  public getProduct(slug: string) {
    return this.productDaoService.getProduct(slug).pipe(
      tap(res => isDevMode() ? console.dir(res) : null)
    )
  }
  
  public addProduct(form: FormGroup) {
    const type = form.get('type').value;
    const name = form.get('name').value;
    const description = form.get('description').value;
    const variants = [];
    for (const v of form.get('variants').value) {
      variants.push({
        image_ids: v.images?.map(image => image.id),
        price: v.price,
        stock: v.stock,
        ean: v.ean,
        sku: v.sku,
        properties: this.generateProperties(v),
      });
    }
    return this.productDaoService.addProduct(type, name, description, variants).pipe(
      tap(res => isDevMode() ? console.dir(res) : null)
    )
  }

  public updateProduct(form: FormGroup) {
    const id = form.get('id')?.value;
    const type = form.get('type').value;
    const name = form.get('name').value;
    const description = form.get('description').value;
    const variants = [];
    for (const v of form.get('variants').value) {
      variants.push({
        id: v.id,
        price: v.price,
        stock: v.stock,
        ean: v.ean,
        sku: v.sku,
        image_ids: v.images?.map(image => image.id),
        properties: this.generateProperties(v),
      });
    }
    return this.productDaoService.updateProduct(id, type, name, description, variants).pipe(
      tap(res => isDevMode() ? console.dir(res) : null)
    )
  }
  
  public deleteProduct(product) {
    return this.productDaoService.deleteProduct(product.id).pipe(
      tap(res => isDevMode() ? console.dir(res) : null)
    )
  }
  
  public deleteProductVariant(variant: FormGroup) {
    return this.productDaoService.deleteProductVariant(variant.get('id').value).pipe(
      tap(res => isDevMode() ? console.dir(res) : null)
    )
  }
  
  private generateProperties(v) {
    const props = {};
    !v.color || (props['color'] = v.color);
    !v.size || (props['size'] = v.size);
    return JSON.stringify(props);
  }

}
