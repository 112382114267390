import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { OrderListComponent } from './components/order-list/order-list.component';
import { OrderRoutingModule } from './order-routing.module';
import { OrderComponent } from './components/order/order.component';
import { OrdersComponent } from './components/orders/orders.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderProductsListComponent } from './components/order-products-list/order-products-list.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { OrderDaoService } from 'src/app/modules/order/services/order-dao.service';
import { OrderService } from './services/order.service';

export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    OrdersComponent,
    OrderComponent,
    OrderListComponent,
    OrderProductsListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    OrderRoutingModule, 
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    OrderService,
    OrderDaoService
  ]
})
export class OrderModule { }
