import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { UserStorageService } from '../modules/shared/services/user-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private userStorageService: UserStorageService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.includes('/signin')) {
            return next.handle(request);
        }

        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${ this.userStorageService.getAccessToken() }`
            }
        });

        return next.handle(request).pipe( 
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401) { return EMPTY }
                    this.userStorageService.deleteAccessToken();
                    this.router.navigate(['signin']);
                }
        }));
    }

}