import { Component, ElementRef, EventEmitter, HostBinding, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { MultimediaService } from './multimedia.service';

@Component({
  selector: 'app-multimedia',
  templateUrl: './multimedia.component.html',
  styleUrls: ['./multimedia.component.scss']
})
export class MultimediaComponent {

  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  @HostBinding('style.display') display: string = 'none';
  @Output() selected = new EventEmitter();
  private listenOpenUploaderSubscription: Subscription;
  private listenCloseUploaderSubscription: Subscription;
  selectedFiles = [];
  files;

  constructor(private multimediaService: MultimediaService) { }

  ngOnInit() {
    this.listenOpenUploader();
    this.listenCloseUploader();
  }
  
  public itemClick(file) {
    const found = this.selectedFiles.find(s => s.id === file.id);
    if (found) {
      this.selectedFiles = this.selectedFiles.filter(s => s.id !== file.id);
    } else {
      this.selectedFiles = [...this.selectedFiles, file];
    }
  }

  public isSelected(file) {
    return !!this.selectedFiles?.find(s => s.id === file.id)
  }

  public select() {
    this.close(this.selectedFiles);
  }

  public close(data?) {
    this.multimediaService.closeUploader(data);
  }

  public uploadFile(file: File) {
    this.multimediaService.uploadFile(file).pipe(
      take(1),
      tap(o => this.getMultimedia())
    ).subscribe();
  }

  private listenOpenUploader() {
    this.listenOpenUploaderSubscription?.unsubscribe();
    this.listenOpenUploaderSubscription = this.multimediaService.listenOpenUploader().pipe(
      tap(res => this.display = 'flex'),
      tap(res => this.getMultimedia())
    ).subscribe();
  }
  
  private listenCloseUploader() {
    this.listenCloseUploaderSubscription?.unsubscribe();
    this.listenCloseUploaderSubscription = this.multimediaService.listenCloseUploader().pipe(
      tap(res => this.display = 'none')
    ).subscribe();
  }

  private getMultimedia() {
    this.multimediaService.getMultimediaBySellerId().pipe(
      tap(res => this.files = res)
    ).subscribe();
  }

}
