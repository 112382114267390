import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { MultimediaService } from 'src/app/modules/shared/components/multimedia/multimedia.service';

@Component({
  selector: 'app-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss']
})
export class ProductImageComponent {

  @Input() images;
  @Output() changes = new EventEmitter();

  constructor(private multimediaService: MultimediaService) { }

  public openMultimediaUploader() {
    const dialog = this.multimediaService.openUploader();
    dialog.pipe(
      take(1),
      tap(file => this.changes.emit(file))
    ).subscribe();
  }

}
