<app-title [content]="'Order ' + order?.get('identifier').value"></app-title>
<app-card>
    <ng-container [formGroup]="order">
        <app-form-field>
            <input type="text" formControlName="identifier" label="Identifier" disabled>
        </app-form-field>
        <app-form-field>
            <span [ngClass]="order.get('status')?.value" label="Status">{{ order.get('status')?.value }}</span>
        </app-form-field>
    </ng-container>
</app-card>
<app-card header="Customer">
    <ng-container [formGroup]="customer">
        <app-form-field>
            <input type="text" formControlName="name" label="Name" disabled>
        </app-form-field>
        <app-form-field>
            <input type="text" formControlName="lastname" label="Lastname" disabled>
        </app-form-field>
        <app-form-field>
            <input type="text" formControlName="email" label="Email" disabled>
        </app-form-field>
        <app-form-field>
            <input type="text" formControlName="phone1" label="Phone 1" disabled>
        </app-form-field>
        <app-form-field>
            <input type="text" formControlName="phone2" label="Phone 2" disabled>
        </app-form-field>
    </ng-container>
</app-card>
<app-card header="Address">
    <ng-container [formGroup]="address">
        <app-form-field>
            <input type="text" formControlName="line1" label="Line 1" disabled>
        </app-form-field>
        <app-form-field>
            <input type="text" formControlName="line2" label="Line 2" disabled>
        </app-form-field>
        <app-form-field>
            <input type="text" formControlName="city" label="City" disabled>
        </app-form-field>
        <app-form-field>
            <input type="text" formControlName="state" label="State" disabled>
        </app-form-field>
        <app-form-field>
            <input type="text" formControlName="zip" label="Zip code" disabled>
        </app-form-field>
        <app-form-field>
            <input type="text" formControlName="country" label="Country" disabled>
        </app-form-field>
    </ng-container>
</app-card>
<app-card header="Products">
    <app-order-products-list [products]="products?.value"></app-order-products-list>
</app-card>
