<table *ngIf="variants?.length > 0">
    <thead>
        <tr>
            <th></th>
            <th></th>
            <th class="center">PRICE</th>
            <th class="center">STOCK</th>
            <th class="center">SIZE</th>
            <th class="center">COLOR</th>
            <th class="center">EAN</th>
            <th class="center">SKU</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let v of variants; let i = index">
            <td></td>
            <td>
                <div class="image">
                    <img [src]="v.images?.[0]?.thumbnail_url" [alt]="v.images?.[0]?.alt_text">
                    <span *ngIf="v.images?.length > 0">1+</span>
                </div>
            </td>
            <td class="center">{{ v.price | currency }}</td>
            <td class="center">{{ v.stock }}</td>
            <td class="center">{{ v.size }}</td>
            <td class="center">{{ v.color }}</td>
            <td class="center">{{ v.ean }}</td>
            <td class="center">{{ v.sku }}</td>
            <td class="right"><button (click)="deleteItem(i)" class="white">BORRAR</button></td>
        </tr>
    </tbody>
</table>
<figure *ngIf="!(variants?.length > 0)">
    <ng-lottie [options]="animationOptions"></ng-lottie>
    <p>Add at least one variant for this product</p>
</figure>