import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RrssAddComponent } from './components/rrss-add/rrss-add.component';
import { RrssDiffusionAddComponent } from './components/rrss-diffusion-add/rrss-diffusion-add.component';
import { RrssEditComponent } from './components/rrss-edit/rrss-edit.component';
import { RrsssComponent } from './components/rrsss/rrsss.component';

const routes: Routes = [
    { path: '', component: RrsssComponent },
    { path: 'add', component: RrssAddComponent },
    { path: 'edit/:rrssConnectionName', component: RrssEditComponent },
    { path: 'diffusion', children: [
      { path: 'add', component: RrssDiffusionAddComponent }
    ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RRSSRoutingModule { }