<article>
    <header>
        <h3>Rrss connections</h3>
        <a [routerLink]="['./add']">Añadir conexión</a>
    </header>
    <div>
        <app-rrss *ngFor="let rrssConnection of rrssConnections" [rrss]="rrssConnection" (click)="editRrssConnection(rrssConnection)"></app-rrss>
    </div>
    <header>
        <h3>Rrss diffusions</h3>
        <a [routerLink]="['./diffusion/add']">Añadir difusión</a>
    </header>
    <div>
        <div *ngFor="let rrssDiffusion of rrssDiffusions">
            <div [routerLink]="['./diffusion/edit', rrssDiffusion.name]" [class.disabled]="rrssDiffusion?.date < now">
                <i *ngIf="rrssDiffusion?.rrssConnection?.type?.name === 'twitter'" class="fas fa-bullhorn"></i>
                <div>
                    <p>{{rrssDiffusion?.name}}</p>
                </div>
                <div>
                    <p>{{rrssDiffusion?.date | date: 'dd/MM/yy hh:mm:ss' }}</p>
                </div>
            </div>
            <i class="fas fa-trash" (click)="deleteRrssDiffusion(rrssDiffusion)"></i>
        </div>
    </div>
</article>