import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AccessGuard } from './guards/access.guard';
import { RolesGuard } from './guards/role.guard';
import { Role } from './types/roles.enum';

const routes: Routes = [
  { path: 'signin', loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule) },
  { path: '', component: LayoutComponent, canActivate: [AccessGuard], children: [
    { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
    { path: 'products', data: { role: Role.SELLER }, canActivate: [RolesGuard], loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule) },
    { path: 'orders', data: { role: Role.SELLER }, canActivate: [RolesGuard], loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule) },
    { path: 'rrss',  data: { role: Role.BLOGGER }, canActivate: [RolesGuard], loadChildren: () => import('./modules/rrss/rrss.module').then(m => m.RRSSModule) },
    { path: 'settings', data: { role: Role.BLOGGER }, canActivate: [RolesGuard], loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule) },
    { path: 'posts', data: { role: Role.BLOGGER }, canActivate: [RolesGuard], loadChildren: () => import('./modules/post/post.module').then(m => m.PostModule) }
  ] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
