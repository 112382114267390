import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { OrderService } from 'src/app/modules/order/services/order.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent {

  order = new FormGroup({
    id: new FormControl(null, Validators.required),
    identifier: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required),
  });
  customer = new FormGroup({
    email: new FormControl(null, Validators.required),
    name: new FormControl(null, Validators.required),
    lastname: new FormControl(null, Validators.required),
    phone1: new FormControl(null, Validators.required),
    phone2: new FormControl(null, Validators.required),
  });
  address = new FormGroup({
    line1: new FormControl(null, Validators.required),
    line2: new FormControl(null, Validators.required),
    city: new FormControl(null, Validators.required),
    state: new FormControl(null, Validators.required),
    zip: new FormControl(null, Validators.required),
    country: new FormControl(null, Validators.required),
  });
  products = new FormArray([]);

  constructor(private activatedRoute: ActivatedRoute,
              private orderService: OrderService) {}

  ngOnInit() {
    this.getSellerOrder();
  }

  private getSellerOrder() {
    const identifier = this.activatedRoute.snapshot.params.identifier;
    this.orderService.getSellerOrder(identifier).pipe(
      take(1),
      map(order => this.resetForm(order)),
    ).subscribe();
  }

  private resetForm(order) {
    this.order.patchValue({
      id: order.id,
      identifier: order.identifier,
      status: order.status,
    });
    this.customer.patchValue({
      email: order.customer.email,
      name: order.customer.name,
      lastname: order.customer.lastname,
      phone1: order.customer.phone1,
      phone2: order.customer.phone2,
    });
    this.address.patchValue({
      line1: order.customer.address.line1,
      line2: order.customer.address.line2,
      city: order.customer.address.city,
      state: order.customer.address.state,
      zip: order.customer.address.zip,
      country: order.customer.address.country
    });
    this.products.clear();
    order?.products?.forEach(p => this.products.push(new FormGroup({
      product_name: new FormControl(p.product_name, Validators.required),
      product_price: new FormControl(p.product_price, Validators.required),
      product_qty: new FormControl(p.product_qty, Validators.required),
    })));
  }

}
