import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent {

  @Input() orders;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  public viewOrder(identifier) {
    this.router.navigate([identifier], { relativeTo: this.activatedRoute })
  }

}
