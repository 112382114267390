import { Component } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { OrderService } from 'src/app/modules/order/services/order.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent {

  orders;

  constructor(private orderService: OrderService) {}

  ngOnInit() {
    this.getOrders();
  }

  public deletedItem() {
    this.getOrders();
  }

  private getOrders() {
    this.orderService.getSellerOrders().pipe(
      take(1),
      map(res => this.orders = res)
    ).subscribe();
  }

}
