<table *ngIf="products?.length > 0">
    <thead>
        <tr>
            <th></th>
            <th></th>
            <th>Slug</th>
            <th>Name</th>
            <th class="center">Min price</th>
            <th class="center">Variants</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let p of products" (click)="viewProduct(p?.product?.slug)">
            <td></td>
            <td>
                <div class="image">
                    <img [src]="p?.variants?.[0]?.images?.[0]?.thumbnail_url" [alt]="p?.variants?.[0]?.images?.[0]?.alt_text">
                </div>
            </td>
            <td [title]="p?.product?.slug">{{ p?.product?.slug }}</td>
            <td [title]="p?.product?.name">{{ p?.product?.name }}</td>
            <td class="center" [title]="p?.product?.min_price | currency">{{ p?.product?.min_price | currency }}</td>
            <td class="center" [title]="p?.variants?.length">{{ p?.variants?.length }}</td>
            <td class="right">
                <button (click)="viewProduct(p?.product?.slug)" title="View"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg></button>
                <button (click)="deleteProduct($event, p)" title="Delete"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M292.31-140q-29.92 0-51.12-21.19Q220-182.39 220-212.31V-720h-40v-60h180v-35.38h240V-780h180v60h-40v507.69Q740-182 719-161q-21 21-51.31 21H292.31ZM680-720H280v507.69q0 5.39 3.46 8.85t8.85 3.46h375.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-720ZM376.16-280h59.99v-360h-59.99v360Zm147.69 0h59.99v-360h-59.99v360ZM280-720v520-520Z"/></svg></button>
            </td>
        </tr>
    </tbody>
</table>
<figure *ngIf="!(products?.length > 0)">
    <ng-lottie [options]="animationOptions"></ng-lottie>
    <p>Add a new product clicking "ADD PRODUCT" button</p>
</figure>
