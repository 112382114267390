<app-breadcrumb [breadcrumbs]="[{ name: 'Posts' }]">
    <button class="green" (click)="addPost()">ADD POST</button>
</app-breadcrumb>
<app-card>
    <app-form-field [formGroup]="searchForm">
        <input type="text" formControlName="search" placeholder="Search" label="Search">
    </app-form-field>
</app-card>
<app-card>
    <table>
        <thead>
            <tr>
                <th></th>
                <th>Name</th>
                <th>Slug</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let post of posts | filterBy: [searchForm.get('search').value, ['slug', 'title']]" [routerLink]="['./edit', post.slug]">
                <td></td>
                <td>{{ post?.title }}</td>
                <td>{{ post?.slug }}</td>
                <td class="right">
                    <button (click)="deletePost(post, $event)" title="View"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg></button>
                </td>
            </tr>
        </tbody>
    </table>
</app-card>
