import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-products-list',
  templateUrl: './order-products-list.component.html',
  styleUrls: ['./order-products-list.component.scss']
})
export class OrderProductsListComponent {

  @Input() products;
  animationOptions = { path: 'assets/animations/empty.animation.json' };

  constructor() { }
}
