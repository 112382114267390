import { Injectable, isDevMode } from '@angular/core';
import { map, tap } from 'rxjs/internal/operators';
import { OrderDaoService } from 'src/app/modules/order/services/order-dao.service';

@Injectable()
export class OrderService {

  constructor(private orderDaoService: OrderDaoService) { }

  public getSellerOrders() {
    return this.orderDaoService.getSellerOrders().pipe(
      tap(res => isDevMode() ? console.dir(res) : null),
      map(res => res?.map(o => 
        ({
          ...o, 
          total_price: this.sumPrices(o.products),
          total_qty: this.sumQty(o.products)
        }))
      )
    )
  }
 
  public getSellerOrder(identifier) {
    return this.orderDaoService.getSellerOrder(identifier).pipe(
      tap(res => isDevMode() ? console.dir(res) : null)
    )
  }

  private sumPrices(products) {
    return (products.reduce((acc, curr) => acc + parseInt(curr.product_price), 0)).toFixed(2);
  }
  private sumQty(products) {
    return products.reduce((acc, curr) => acc + curr.product_qty, 0);
  }
}
