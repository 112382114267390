import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { PostAddComponent } from './components/post-add/post-add.component';
import { PostEditComponent } from './components/post-edit/post-edit.component';
import { PostsComponent } from './components/posts/posts.component';
import { PostRoutingModule } from './post-routing.module';
import { PostService } from './services/post.service';
import { PostDaoService } from './services/post-dao.service';

@NgModule({
  declarations: [
    PostAddComponent,
    PostEditComponent,
    PostsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PostRoutingModule
  ],
  providers: [
    PostService,
    PostDaoService
  ]
})
export class PostModule { }
