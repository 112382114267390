<article>
    <header>
        <h3>Add rrss connection</h3>
        <a [routerLink]="['/rrss']">atrás</a>
    </header>
    <div [formGroup]="rrssConnectionForm">
        <app-form-field>
            <input type="text" formControlName="name" placeholder="Account alias" label="Account alias">
        </app-form-field>
        <app-form-field>
            <select formControlName="rrssType" [compareWith]="compareFn" placeholder="Type" label="Type">
                <option *ngFor="let rrssType of rrssTypes" [ngValue]="rrssType">{{ rrssType.name }}</option>
            </select>
        </app-form-field>
        <app-form-field>
            <textarea formControlName="tokens" rows="10" placeholder="Tokens" label="Tokens"></textarea>      
        </app-form-field>
    </div>
    <button (click)="addRrss()" [disabled]="saving">Añadir</button>
</article>