import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { SharedModule } from '../shared/shared.module';
import { ProductAddComponent } from './components/product-add/product-add.component';
import { ProductImageComponent } from './components/product-image/product-image.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductVariantsComponent } from './components/product-variants/product-variants.component';
import { ProductEditComponent } from './components/product-edit/product-edit.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductRoutingModule } from './product-routing.module';
import { PrintfulDaoService } from './services/printful-dao.service';
import { ProductDaoService } from './services/product-dao.service';
import { ProductService } from './services/product.service';
import { PrintfulService } from './services/printful.service';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    ProductsComponent,
    ProductListComponent,
    ProductAddComponent,
    ProductEditComponent,
    ProductVariantsComponent,
    ProductImageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ProductRoutingModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    ProductService,
    ProductDaoService,
    PrintfulService,
    PrintfulDaoService
  ]
})
export class ProductModule { }
