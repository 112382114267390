import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { ProductService } from 'src/app/modules/product/services/product.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent {

  @Input() products;
  @Output() delete = new EventEmitter();
  animationOptions = { path: 'assets/animations/empty.animation.json' };

  constructor(
    private productService: ProductService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  public viewProduct(slug) {
    this.router.navigate([slug], { relativeTo: this.activatedRoute })
  }

  public deleteProduct(e, product) {
    e.stopPropagation();
    this.productService.deleteProduct(product.product).pipe(
      take(1),
      tap(o => this.delete.emit())
    ).subscribe();
  }

}
