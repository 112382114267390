import { Component, OnInit } from '@angular/core';
import { UserStorageService } from 'src/app/modules/shared/services/user-storage.service';
import { Role } from 'src/app/types/roles.enum';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss']
})
export class AsideComponent {

  readonly ROLE = Role;
  role;

  constructor(private userStorageService: UserStorageService) { 
    this.role = this.userStorageService.getRole();
  }

}
