<div>
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
        <ng-container *ngIf="i > 0">
            <span>></span>
        </ng-container>
        <ng-container *ngIf="breadcrumb.link">
            <a routerLink="breadcrumb.link">{{ breadcrumb.name }}</a>
        </ng-container>
        <ng-container *ngIf="!breadcrumb.link">
            <span>{{ breadcrumb.name }}</span>
        </ng-container>
    </ng-container>
</div>
<div>
    <ng-content></ng-content>
</div>