<article>
    <header>
        <h3>Add diffusion</h3>
        <a [routerLink]="['/rrss']">atrás</a>
    </header>
    <div [formGroup]="rrssDiffusionForm">
        <app-form-field>
            <input type="text" formControlName="name" placeholder="Alias" label="Alias">
        </app-form-field>
        <app-form-field>
            <textarea formControlName="content" rows="10" placeholder="Content" label="Content"></textarea>      
        </app-form-field>
        <app-form-field>
            <input type="datetime-local" formControlName="date" placeholder="Schedule" label="Schedule">
        </app-form-field>
        <app-form-field>
            <select formControlName="rrssConnection" [compareWith]="compareFn" placeholder="Connection" label="Connection">
                <option *ngFor="let rrssConnection of rrssConnections" [ngValue]="rrssConnection">{{ rrssConnection.type.name }} - {{ rrssConnection.name }}</option>
            </select>
        </app-form-field>
    </div>
    <button (click)="addDiffusion()" [disabled]="saving">Añadir</button>
</article>