<article>
    <header>
        <h3>Settings</h3>
    </header>
    <div>
        <div>
            <p>Generate XML sitemap</p>
            <div>
                <button (click)="generate()">Generate</button>
            </div>
        </div>
    </div>
</article>