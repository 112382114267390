import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/internal/operators/map';

@Injectable()
export class UserDaoService {

  constructor(private apollo: Apollo) { }

  public signIn(email, password) {
    const signInInput = { email, password };
    const signInQuery = gql`
      query signIn($signInInput: SignInInput!){
        signIn(signInInput: $signInInput)
      }
    `;

    return this.apollo.watchQuery<any>({
      query: signInQuery,
      variables: { signInInput }}).valueChanges.pipe( map(result => result.data.signIn));
  }
  
  public sellerSignIn(email, password) {
    const sellerSignInInput = { email, password };
    const sellerSignInQuery = gql`
      query sellerSignIn($sellerSignInInput: SellerSignInInput!){
        sellerSignIn(sellerSignInInput: $sellerSignInInput)
      }
    `;

    return this.apollo.watchQuery<any>({
      query: sellerSignInQuery,
      variables: { sellerSignInInput }}).valueChanges.pipe( map(result => result.data.sellerSignIn));
  }

}
