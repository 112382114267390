import { Injectable, isDevMode } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/internal/operators';
import { UserDaoService } from 'src/app/modules/user/services/user-dao.service';
import { Role } from 'src/app/types/roles.enum';
import { UserStorageService } from '../../shared/services/user-storage.service';

@Injectable()
export class UserService {

  constructor(private userStorageService: UserStorageService,
              private userDaoService: UserDaoService) { }

  public signIn(role, email, password): Observable<any> {
    switch (role) {
      case 'BLOGGER':
        return this.userDaoService.signIn(email, password).pipe(
          tap(res => isDevMode() ? console.dir(res) : null),
          tap(res => this.userStorageService.setAccessToken(res)),
          tap(res => this.userStorageService.setRole(Role.BLOGGER)),
          map(res => res)
        );
      case 'SELLER':
        return this.userDaoService.sellerSignIn(email, password).pipe(
          tap(res => isDevMode() ? console.dir(res) : null),
          tap(res => this.userStorageService.setAccessToken(res)),
          tap(res => this.userStorageService.setRole(Role.SELLER)),
        map(res => res)
      );
    }
  }

}
