<div></div>
<ul>
    <li [routerLink]="['/home']" routerLinkActive="active">
        <i class="fas fa-home"></i>
        <span>Home</span>
    </li>
    <li *ngIf="role === ROLE.BLOGGER" [routerLink]="['/posts']" routerLinkActive="active">
        <i class="fas fa-file-alt"></i>
        <span>Posts</span>
    </li>
    <li *ngIf="role === ROLE.SELLER" [routerLink]="['/orders']" routerLinkActive="active">
        <i class="fas fa-cash-register"></i>
        <span>Orders</span>
    </li>
    <li *ngIf="role === ROLE.SELLER" [routerLink]="['/products']" routerLinkActive="active">
        <i class="fas fa-boxes"></i>
        <span>Products</span>
    </li>
    <li *ngIf="role === ROLE.BLOGGER" [routerLink]="['/rrss']" routerLinkActive="active">
        <i class="fas fa-bullhorn"></i>
        <span>RRSS</span>
    </li>
    <li *ngIf="role === ROLE.BLOGGER" [routerLink]="['/settings']" routerLinkActive="active">
        <i class="fas fa-cogs"></i>
        <span>Settings</span>
    </li>
</ul>
