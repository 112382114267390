<div (click)="close()"></div>
<div>
    <section>
        <div>
            <app-multimedia-upload-item (upload)="uploadFile($event)"></app-multimedia-upload-item>
            <figure *ngFor="let file of files" (click)="itemClick(file)" [class.selected]="isSelected(file)" [title]="file.alt_text">
                <img [src]="file.thumbnail_url" [alt]="file.alt_text">
                <div>
                    <span>{{ file.alt_text }}</span>
                </div>
            </figure>
        </div>
    </section>
    <footer>
        <button class="green" [disabled]="!(selectedFiles?.length > 0)" (click)="select()">SELECT {{ selectedFiles?.length > 0 ? (selectedFiles?.length + ' files') : '' }}</button>
    </footer>
</div>