import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FieldNameDirective } from 'src/app/modules/shared/directives/fieldname.directive';
import { AsideComponent } from './components/aside/aside.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CardComponent } from './components/card/card.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { MultimediaUploadItemComponent } from './components/multimedia/multimedia-upload-item/multimedia-upload-item.component';
import { MultimediaComponent } from './components/multimedia/multimedia.component';
import { TitleComponent } from './components/title/title.component';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { FilterByPipe } from './pipes/filter-by.pipe';
import { UserStorageService } from './services/user-storage.service';

@NgModule({
  declarations: [
    AsideComponent,
    BreadcrumbComponent,
    FormFieldComponent,
    CardComponent,
    TitleComponent,
    FieldNameDirective,
    CustomCurrencyPipe,
    MultimediaComponent,
    MultimediaUploadItemComponent,
    FilterByPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    UserStorageService
  ],
  exports: [
    BreadcrumbComponent,
    FormFieldComponent,
    CardComponent,
    TitleComponent,
    FieldNameDirective,
    CustomCurrencyPipe,
    MultimediaComponent,
    FilterByPipe,
    AsideComponent
  ]
})
export class SharedModule { }
